// import axios from "axios";
import { Button, Heading, Img, Text } from "components";
// import BaseUrl from "context/BaseUrl";
import React from "react";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Footer() {
  const navigate = useNavigate();
  // const [suggestion, setSuggestion] = useState("");
  // const [number, setNumber] = useState("");

  // async function handlePostSuggestion() {
  //   const raw = JSON.stringify({
  //     suggestions: suggestion,
  //   });
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: BaseUrl + "Api/suggestion",
  //       data: raw,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     if (response?.status === 200) {
  //       suggestion("");
  //     }
  //     console.log(response.data);
  //     toast.success("Post request successful");
  //   } catch (error) {
  //     console.error("There was a problem with your Axios request:", error);
  //     toast.error("Error while making post request");
  //   }
  // }

  async function handlePostNumber() {
    navigate("/contact");
    // const raw = JSON.stringify({
    //   ContactNumber: number,
    // });
    // try {
    //   const response = await axios({
    //     method: "post",
    //     url: BaseUrl + "Api/ContactNumber",
    //     data: raw,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   });
    //   if (response?.status === 201) {
    //     setNumber("")
    //   }
    //   toast.success("Contact Post successful.");
    // } catch (error) {
    // console.error("There was a problem with your Axios request:", error);
    //   toast.error("Error while making post request");
    // }
  }
  return (
    <>
      <footer className="flex justify-center items-center md:overflow-hidden overflow-visible w-full mt-[-100px] z-[1]">
        <div className="h-[480px] md:h-auto bg-transparent md:bg-[#f1e3bf] w-full relative md:py-12 py-6">
          <Img
            src="images/img_vector_4.png"
            alt="vectorfour_one"
            className="justify-center flex md:hidden h-[533px] w-full left-0 bottom-0 right-0 top-0 m-auto md:m-0 object-cover absolute rounded-[1px]"
          />

          <div className="flex flex-row md:flex-col justify-center w-[90%] top-[146px] md:top-[50px] right-0 left-0 m-auto absolute md:relative gap-[20px] md:gap-[40px]">
            <div className="footer-right md:w-full">
              <div className="sub-box">
                <div className="bg-white-A700 max-w-[287px] m-auto rounded-[30px] md:my-[30px] my-[57px]">
                  <button
                    onClick={() => handlePostNumber()}
                    className="bg-[#F1E3BF] w-full rounded-[30px] px-[25px] py-[12px] md:py-[10px] text-[18px] text-[#000]"
                  >
                    सिंधिया से जुड़े
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col items-start justify-start w-full max-w-[1242px]">
                <div className="grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-5 md:gap-[40px] justify-between items-start w-full">
                  <div className="flex flex-col justify-start items-start w-[100%] gap-[18px]">
                      <Img
                        src="images/img_image_1_86x84.png"
                        alt="imageone_three"
                        className="w-[35%] object-cover"
                      />
                      <Heading as="h5" className="w-[59%]">
                        ज्योतिरादित्य मा. सिंधिया
                      </Heading>
                    <div className="flex flex-row justify-start mt-4 gap-2.5">
                      <a
                        href="https://www.facebook.com/JMScindia/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="w-[30px]">
                          <Img src="images/img_ri_facebook_fill.svg" />
                        </Button>
                      </a>
                      <a
                        href="https://twitter.com/JM_Scindia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="w-[30px]">
                          <Img src="images/img_pajamas_twitter.svg" />
                        </Button>
                      </a>
                      <a
                        href="https://www.youtube.com/user/jyotiradityascindia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="w-[30px]">
                          <Img src="images/img_group_94.svg" />
                        </Button>
                      </a>
                      <a
                        href="https://www.instagram.com/jyotiradityascindia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="w-[30px]">
                          <Img src="images/img_ri_instagram_line.svg" />
                        </Button>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/jyotiradityamscindia"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="w-[30px]">
                          <Img src="images/img_group_92.svg" />
                        </Button>
                      </a>
                    </div>
                    <Text
                      as="p"
                      className="sm:text-[14px] w-[90%] sm:w-full text-[24px] md:text-[16px]"
                    >
                      Address: 27, Safdarjung Road, New Delhi
                    </Text>
                  </div>
                  <div className="flex flex-col items-start justify-start w-[100%] mb-0.5 gap-px">
                    <div className="flex flex-col gap-[15px] justify-start items-end w-full">
                      <a
                        href="/"
                        className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                          className="mr-4 md:mr-2"
                        />
                        <Text as="p" className="text-[18px] md:text-[14px]">
                          होम
                        </Text>
                      </a>
                      <a
                        href="/parichay"
                        className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                          className="mr-4 md:mr-2"
                        />
                        <Text as="p" className="text-[18px] md:text-[14px]">
                          परिचय
                        </Text>
                      </a>
                      <a
                        href="/gallery"
                        className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                          className="mr-4 md:mr-2"
                        />
                        <Text as="p" className="text-[18px] md:text-[14px]">
                          गैलरी
                        </Text>
                      </a>
                      {/* <a
                        href="/vade-irade"
                        className="flex flex-row justify-start items-center w-[100%] gap-[30px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                        />
                        <Text as="p" size="xl">
                          वादे और इरादे
                        </Text>
                      </a> */}
                      <a
                        href="/press"
                        className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                          className="mr-4 md:mr-2"
                        />
                        <Text as="p" className="text-[18px] md:text-[14px]">
                          प्रेस एवं मीडिया
                        </Text>
                      </a>
                      <a
                        href="/contact"
                        className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]"
                      >
                        <Img
                          src="images/small-right-arrow.svg"
                          alt="imageone_three"
                          className="mr-4 md:mr-2"
                        />
                        <Text as="p" className="text-[18px] md:text-[14px]">
                          सम्पर्क करें
                        </Text>
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[15px] justify-between items-start w-[100%]">
                    <div className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]">
                      <Img
                        src="images/small-right-arrow.svg"
                        alt="imageone_three"
                        className="mr-4 md:mr-2"
                      />
                      <a href="/podcast">
                        <Text as="p" className="text-[18px] md:text-[14px]">Podcasts</Text>
                      </a>
                    </div>
                    <div className="flex flex-row justify-start items-center w-[100%] gap-[15px] md:gap-[10px]">
                      <Img
                        src="images/small-right-arrow.svg"
                        alt="imageone_three"
                        className="mr-4 md:mr-2"
                      />
                      <a href="/work-done">
                        <Text as="p" className="text-[18px] md:text-[14px]">Work Done In Aviation</Text>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Img
                src="images/img_group_17.svg"
                alt="image_five"
                className="h-px mt-10 md:mt-8 w-full"
              />
              <Text
                size="xs"
                as="p"
                className="mt-[19px] md:mt-4 !text-black-900_7f !font-unbounded md:text-[12px] md:pb-4"
              >
                Copyright @ 2024. All rights reserved.
              </Text>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
