import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Button, Heading } from "../../components";
import Header from "components/Header";
import HeroSlider from "components/Slider/HeroSlider";
import Footer from "components/footer/Footer";
import Gallery from "components/Slider/Gallery";
import Media from "components/Slider/Media";
import { Link } from "react-router-dom";
import a1 from "../../assets/new/Jivani.jpg";
import a2 from "../../assets/new/VikasKarya.jpg";
import a3 from "../../assets/new/Aviation.JPG";
import a4 from "../../assets/new/steel.jpeg";

export default function DesktopTwelvePage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleDownload = (imgUrl) => {
    const link = document.createElement("a");
    link.href = imgUrl;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Helmet>
        <title>Jyotiraditya Scindia</title>
        <meta property="images/web-img.jpg" content="jyotiradityascindia" />
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start">
        <div className="flex flex-col items-center justify-start w-full">
          <Header />
          <div className="w-full">
            <div className=" w-full h-full">
              <div className="hero-slider">
                <HeroSlider />
              </div>
              <div className="bg-2">
                <div className="bg-2-content">
                  जनसेवा को अपना ध्येय और राजनीति को उसका माध्यम मात्र मानने
                  वाले ज्योतिरादित्य सिंधिया वर्तमान में भारत सरकार में केंद्रीय
                  नागरिक उड्डयन एवं इस्पात मंत्री के रूप में कार्यरत हैं। वे
                  मध्य प्रदेश के गुना क्षेत्र से चार बार लोकसभा सांसद रहे हैं और
                  लोक सभा चुनाव 2024 में गुना से भाजपा के प्रत्याशी हैं।
                  <div className="wrapper"></div>
                </div>
              </div>
              {/* <Img
                src="images/img/bg-2.png"
                alt="image"
                className="w-full mt-[-8px] sm:mt-[0] object-cover"
              /> */}
            </div>
            {/* <div className="flex flex-row justify-center items-center w-full bottom-0 right-0 left-0 m-auto absolute">
              <Img
                src="images/img_rectangle_125.png"
                alt="image_one"
                className="w-[18%] z-[1] object-cover rounded-[16px]"
              />
              <Img
                src="images/img_rectangle_124.png"
                alt="image_two"
                className="w-full ml-[-249px] object-cover"
              />
            </div> */}
            {/* <div className="flex flex-row justify-center w-[26%] bottom-[18%] left-[8%] p-[19px] m-auto bg-white-A700 absolute rounded-[16px]">
              <Text
                as="p"
                className="mt-[35px] mb-[22px] !font-rhodiumlibre !leading-7"
              >
                जनसेवा को अपना ध्येय और राजनीति को उसका माध्यम मात्र मानने वाले
                ज्योतिरादित्य सिंधिया वर्तमान में भारत सरकार में केंद्रीय नागरिक
                उड्डयन एवं इस्पात मंत्री के रूप में कार्यरत हैं। वे मध्यप्रदेश
                से चार बार लोकसभा सांसद रहे हैं (2002-04, 2004-09, 2009-14,
                2014-19) एवं वर्तमान में वे मध्यप्रदेश से राज्यसभा सदस्य के रूप
                में राज्य का प्रतिनिधित्व कर रहे हैं।{" "}
              </Text>
            </div> */}
          </div>
          <div className="flex flex-row justify-center w-full">
            <div className="flex flex-col items-center justify-center w-full pl-[75px] sm:pl-[14px] sm:pr-[14px] pr-14 gap-[100px] sm:gap-[30px] py-[75px] bg-orange-50_e5">
              <div className="flex flex-row justify-center items-center">
                <div className="flex flex-col items-center justify-start w-full">
                  <Img
                    src="images/img_image_1.png"
                    alt="imagetwo_one"
                    className="w-[23%] object-cover"
                  />
                  <Heading
                    size="lg"
                    as="h2"
                    className="mt-[0] sm:text-[20px] !font-alegreya"
                  >
                    भारतीय जनता पार्टी
                  </Heading>
                </div>
              </div>
              <div className="flex flex-row sm:flex-col items-center w-full mb-[41px] gap-[30px] sm:gap-[20px] mx-auto max-w-[1234px]">
                <div>
                  <Link to="/parichay">
                    <Img
                      // src="images/img/bj4.png"
                      src={a1}
                      alt="imagetwo_one"
                      className="party-img"
                    />
                    <div className="flex justify-center w-[286px] h-[93px] items-center p-[25px] border-black-900_01 border border-solid bg-gradient party-box">
                      <Text size="4xl" as="p" className="mb-0.5">
                        जीवनी
                      </Text>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/vikas">
                    <Img
                      // src="images/img/bj2.png"
                      src={a2}
                      alt="imagetwo_one"
                      // className="party-img top-[-240px] sm:top-[-220px] rounded-lg"
                      className="party-img"
                    />
                    <div className="flex justify-center items-center w-[286px] h-[93px] p-[25px] border-black-900_01 border border-solid bg-gradient  party-box">
                      <Text size="4xl" as="p" className="mb-1.5">
                        विकास कार्य
                      </Text>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/work-done">
                    <Img
                      src={a3}
                      // src="images/img/bj3.jpg"
                      alt="imagetwo_one"
                      className="party-img"
                    />
                    <div className="flex party-box justify-center items-center w-[286px] h-[93px] px-[22px] border-black-900_01 border border-solid bg-gradient">
                      <Text
                        size="4xl"
                        as="p"
                        className="w-[95%] text-center !font-normal !leading-10"
                      >
                        विमानन क्षेत्र की ऊँची उड़ान
                      </Text>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/work-done#ispat">
                    <Img src={a4} alt="imagetwo_one" className="party-img" />
                    <div className="flex party-box justify-center items-center w-[286px] h-[93px] px-[22px] border-black-900_01 border border-solid bg-gradient">
                      <Text
                        size="4xl"
                        as="p"
                        className="mt-[5px] text-center !font-normal !leading-10"
                      >
                        इस्पात क्षेत्र को मिली नई मजबूती
                      </Text>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <Heading
            size="lg"
            as="h2"
            className="mt-[69px] sm:text-[20px] text-center !font-alegreya"
          >
            #गुना_दिल_से
          </Heading>
          <div className="sm:mt-[20px] mt-[60px]">
            <button onClick={() => handleDownload("/images/gunna.jpg")}>
              <img
                src="/images/gunna.jpg"
                className="w-full h-auto p-[20px]"
                alt=""
              />
            </button>
          </div>
          <Heading
            size="lg"
            as="h2"
            className="mt-[69px] sm:text-[20px] text-center !font-alegreya"
          >
            Jyotiraditya Scindia on Social Media
          </Heading>
          <div className="justify-center w-full mt-16 gap-[30px] sm:gap-[15px] grid-cols-3 sm:grid-cols-1 px-[14px] grid max-w-[1239px]">
            <div className="flex flex-col items-center justify-start w-full bg-blue_gray-100 rounded-[16px]">
              <a
                href="https://www.instagram.com/jyotiradityascindia"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-row justify-between items-center w-full p-[11px] rounded-tl-[16px] rounded-tr-[16px] bg-yellow-900">
                    <div className="flex flex-row justify-start items-center gap-3.5">
                      <Button color="deep_orange_300" className="w-[30px]">
                        <Img src="images/img_ri_instagram_line_white_a700.svg" />
                      </Button>
                      <Heading
                        as="h2"
                        className="!text-white-A700 !font-outfit"
                      >
                        jyotiradityascindia
                      </Heading>
                    </div>
                    <Text
                      size="md"
                      as="p"
                      className="mr-[7px] !text-white-A700 !font-outfit underline"
                    >
                      View
                    </Text>
                  </div>
                  <div className="flex flex-row justify-start items-center w-full gap-px">
                    <Img
                      src="images/img_whatsapp_image_2024_02_24.png"
                      alt="view_two"
                      className="w-[100%] rounded-bl-[16px] object-cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-start w-full bg-blue_gray-100 rounded-[16px]">
              <a
                href="https://www.facebook.com/JMScindia/"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-row justify-between items-center w-full p-[11px] rounded-tl-[16px] rounded-tr-[16px] bg-yellow-900">
                    <div className="flex flex-row justify-start items-center gap-[17px]">
                      <Button color="deep_orange_300" className="w-[30px]">
                        <Img src="images/img_ri_facebook_fill_white_a700.svg" />
                      </Button>
                      <Heading
                        as="h3"
                        className="!text-white-A700 !font-outfit"
                      >
                        Jyotiraditya M Scindia
                      </Heading>
                    </div>
                    <Text
                      size="md"
                      as="p"
                      className="mr-1.5 !text-white-A700 !font-outfit underline"
                    >
                      View
                    </Text>
                  </div>
                  <div className="flex flex-row justify-start items-center w-full gap-px">
                    <Img
                      src="images/img_whatsapp_image_2024_02_24_354x375.png"
                      alt="whatsappimage"
                      className="w-[100%] rounded-bl-[16px] object-cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-start w-full bg-blue_gray-100 rounded-[16px]">
              <a
                href="https://twitter.com/JM_Scindia"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-row justify-between items-center w-full p-[11px] rounded-tl-[16px] rounded-tr-[16px] bg-yellow-900">
                    <div className="flex flex-row justify-start items-center gap-[17px]">
                      <Button color="deep_orange_300" className="w-[30px]">
                        <Img src="images/img_pajamas_twitter_white_a700.svg" />
                      </Button>
                      <Heading
                        as="h4"
                        className="!text-white-A700 !font-outfit"
                      >
                        Jyotiraditya M. Scindia
                      </Heading>
                    </div>
                    <Text
                      size="md"
                      as="p"
                      className="mr-[7px] !text-white-A700 !font-outfit underline"
                    >
                      View
                    </Text>
                  </div>
                  <div className="flex flex-row justify-start items-center w-full gap-px">
                    <Img
                      src="images/img_whatsapp_image_2024_02_24_353x375.png"
                      alt="whatsappimage"
                      className="w-[100%] mt-px rounded-bl-[16px] object-cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-start w-full bg-blue_gray-100 rounded-[16px]">
              <a
                href="https://www.linkedin.com/in/jyotiradityamscindia"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-row justify-between items-center w-full p-[11px] rounded-tl-[16px] rounded-tr-[16px] bg-yellow-900">
                    <div className="flex flex-row justify-start items-center gap-[17px]">
                      <Button color="deep_orange_300" className="w-[30px]">
                        <Img src="images/img_group_114.svg" />
                      </Button>
                      <Heading
                        as="h5"
                        className="!text-white-A700 !font-outfit"
                      >
                        Jyotiraditya M Scindia
                      </Heading>
                    </div>
                    <Text
                      size="md"
                      as="p"
                      className="mr-[7px] !text-white-A700 !font-outfit underline"
                    >
                      View
                    </Text>
                  </div>
                  <div className="flex flex-row justify-start items-center w-full gap-px">
                    <Img
                      src="images/img_whatsapp_image_2024_02_24_1.png"
                      alt="whatsappimage"
                      className="w-[100%] rounded-bl-[16px] object-cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="flex flex-col items-center justify-start w-full bg-blue_gray-100 rounded-[16px]">
              <a
                href="https://www.youtube.com/user/jyotiradityascindia"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="flex flex-row justify-between items-center w-full p-[11px] rounded-tl-[16px] rounded-tr-[16px] bg-yellow-900">
                    <div className="flex flex-row justify-start items-center gap-[17px]">
                      <Button color="deep_orange_300" className="w-[30px]">
                        <Img src="images/img_mdi_youtube.svg" />
                      </Button>
                      <Heading
                        as="h6"
                        className="!text-white-A700 !font-outfit"
                      >
                        @JyotiradityaScindia
                      </Heading>
                    </div>
                    <Text
                      size="md"
                      as="p"
                      className="mr-px !text-white-A700 !font-outfit underline"
                    >
                      View
                    </Text>
                  </div>
                  <div className="flex flex-row justify-start items-center w-full gap-px">
                    <Img
                      src="images/img_whatsapp_image_2024_02_24_2.png"
                      alt="whatsappimage"
                      className="w-[100%] rounded-bl-[16px] object-cover"
                    />
                  </div>
                </div>
              </a>
            </div>
            <div className="h-[406px] sm:h-[200px] w-full relative">
              <a
                href="https://www.youtube.com/user/jyotiradityascindia"
                target="_blank"
                className="flex flex-row justify-between items-center w-full"
                rel="noopener noreferrer"
              >
                <Img
                  src="images/img_rectangle_29.png"
                  alt="image"
                  className="justify-center h-[406px] sm:h-[200px] w-full left-0 bottom-0 right-0 top-0 m-auto object-cover absolute rounded-[16px]"
                />
                <Button
                  size="lg"
                  shape="square"
                  className="w-[80px] left-0 bottom-0 right-0 top-0 m-auto absolute"
                >
                  <Img src="images/img_octicon_play_24.svg" />
                </Button>
              </a>
            </div>
          </div>
          <div className="max-w-[1240px] mt-[50px] m-auto w-full">
            <div className="grid grid-cols-2 w-full sm:grid-cols-1 gap-5 px-[20px]">
              <iframe
                className="h-[315px] sm:h-full w-full"
                src="https://www.youtube.com/embed/RIx1UJAeNig?si=C13R3PFwOjDMz7BH"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              <iframe
                className="h-[315px] sm:h-full w-full"
                src="https://www.youtube.com/embed/ALgditJBH2s?si=HoS1LyjPG1LtLO94"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="flex flex-col bg-[#FFFAEE] overflow-hidden items-center justify-start w-full mt-[82px]">
            <div className="flex flex-col items-center justify-start w-full overflow-hidden">
              <div className="h-[1523px] sm:h-[1100px] overflow-hidden w-full relative max-w-[1778px]">
                <div className="justify-center overflow-hidden w-[100%] left-0 bottom-0 right-0 top-0 m-auto bg-yellow-50" />
                <div className="w-full gap-2.5 overflow-hidden py-6">
                  <Heading
                    size="lg"
                    as="h2"
                    className="!font-alegreya text-center mb-4"
                  >
                    Gallery
                  </Heading>
                  <div className="gallery-slider">
                    <Gallery />
                  </div>
                  <div className="text-center mt-[20px] w-full flex items-center justify-center">
                    <a
                      href="/gallery"
                      className="bg-[#f4660d] text-[#fff] rounded-[50px] py-[12px] w-[200px]"
                    >
                      Visit Gallery
                    </a>
                  </div>
                  {/* <Img
                    src="images/img_rectangle_119.png"
                    alt="rectangle"
                    className="w-[20%] object-cover rounded"
                  />
                  <Img
                    src="images/img_rectangle_118.png"
                    alt="rectangle"
                    className="w-[20%] object-cover rounded"
                  />
                  <Img
                    src="images/img_rectangle_120.png"
                    alt="rectangle"
                    className="w-[20%] object-cover rounded"
                  />
                  <Img
                    src="images/img_rectangle_121.png"
                    alt="rectangle"
                    className="w-[20%] object-cover rounded"
                  />
                  <Img
                    src="images/img_rectangle_122.png"
                    alt="rectangle"
                    className="w-[20%] object-cover rounded"
                  /> */}
                </div>
                <div className="flex flex-row justify-center w-[70%] sm:w-[96%] m-auto">
                  <div className="flex-col items-end justify-start w-full gap-[116px] sm:gap-[50px]">
                    <div className="flex flex-row justify-center items-center w-[100%] gap-[29px] ">
                      <Heading size="lg" as="h3" className="!font-alegreya text-center">
                        Media Article
                      </Heading>

                      <Img
                        src="images/img_arrow_16.svg"
                        alt="arrowsixteen"
                        className="h-[16px]"
                      />
                    </div>
                    <div className="media-slider">
                      <Media />
                    </div>
                    <div className="flex flex-row justify-center items-center w-[100%] mt-[20px] ">
                      <a
                        href="/press"
                        className="bg-[#f4660d] text-center text-[#fff] rounded-[50px] py-[12px] w-[200px]"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
